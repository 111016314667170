<template>
  <div class="tool-view">
    <div class="option-box">
      调用：
      <template
        v-for="option in Platforms"
        :key="option.value"
      >
        <!-- 过滤掉当前平台 -->
        <a-checkbox
          :checked="option.value === checkedOption"
          :disabled="isLoading"
          @change="changeCheckedOption(option.value, $event)"
          v-if="option.value !== props.platform"
        >
          {{ option.text }}
        </a-checkbox>
      </template>
      <!-- 单独新增PC -->
      <a-checkbox
        v-if="$pub.ljProjectIDs().includes($pub.projectID()) && 7 !== props.platform"
        :checked="7 === checkedOption"
        :disabled="isLoading"
        @change="changeCheckedOption(7, $event)"
      >
        PC
      </a-checkbox>
    </div>
    <a-button type="primary" @click="handleAdd()"><plus-outlined />添加板块</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :loading="isLoading"
    :pagination="false"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record, index }">
      <!-- 剧名 -->
      <template v-if="column.key === 'content'">
        <div v-for="(plays, ind) in record.content" :key="ind" class="play-name">
          <span v-for="(play, i) in plays" :key="i">
          <!-- 名称 -->
            {{ play.name }}
            <!-- 分隔符 -->
            <span class="gap" v-if="plays.length > i + 1">|</span>
          </span>
        </div>
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
      <!-- 编辑 -->
        <a-button
          type="link"
          @click="handleAdd(record)"
        >
          编辑
        </a-button>
        <!-- 删除  -->
        <a-popconfirm
          title="确定要删除吗？"
          @confirm="handleDelete(record)"
        >
          <a-button type="link">删除</a-button>
        </a-popconfirm>
        <!-- 上移：第一组禁用 -->
        <a-button
          type="link"
          :disabled="index === 0"
          @click="handleSort(record, 'up')"
        >
          上移
        </a-button>
        <!-- 下移：最后一组禁用 -->
        <a-button
          type="link"
          :disabled="index === dataSource.length - 1"
          @click="handleSort(record, 'down')"
        >
          下移
        </a-button>
      </template>
    </template>
  </a-table>
  <!-- 新增、编辑抽屉 -->
  <Add ref="RefAdd" @success="getFrontSourceList"></Add>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { Platforms, FrontSource} from '@/utils/constantList'
import { message } from 'ant-design-vue'
import Add from './Add'
import { frontSourceSettingDetail, frontSourceSetting, frontSourceList, frontSourceSort, frontSourceDelete } from '@/api/operate'

// 新增、编辑组件实例
let RefAdd = ref(null)
// props
const props = defineProps({
  platform: Number
})
// source类型
const source = FrontSource.module
// 公共设置详情
let settingDetail = ref({})
// 调用项选中状态
let checkedOption = ref([])
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 列数据源（付费转化率、权重本期不做）
let columns = ref([
  {
    title: '顺序',
    dataIndex: 'sort',
    key: 'sort'
  },
  {
    title: '板块名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '短剧',
    dataIndex: 'content',
    key: 'content'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount(() => {
  // 获取公共配置详情
  getFrontSourceSettingDetail()
  // 获取配置列表
  getFrontSourceList()
})

// 获取公共配置详情
function getFrontSourceSettingDetail () {
  isLoading.value = true
  frontSourceSettingDetail({
    source,
    platform_id: props.platform
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      if (data) {
        // 保存顶部公共设置
        settingDetail.value = data
        // 调用平台
        checkedOption.value = data.use_platform_id
      }
    } else {
      message.error(res.message || msg)
    }
  })
}

// 获取配置列表
function getFrontSourceList () {
  isLoading.value = true
  frontSourceList({
    source,
    platform_id: props.platform
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dataSource.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}

// 设置公共配置
function setFrontSourceSetting (obj = {}) {
  isLoading.value = true
  const params = { 
    source,
    platform_id: props.platform,
    use_platform_id: checkedOption.value,
    // 覆盖项
    ...obj
  }
  frontSourceSetting(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 更新详情
      getFrontSourceSettingDetail()
    } else {
      message.error(res.message || msg)
    }
  })
}


// 修改调用项勾选状态（单选！选中传id，取消则传自身平台 platform-id）
function changeCheckedOption (id, e) {
  setFrontSourceSetting({
    use_platform_id: e.target.checked ? id : props.platform
  })
}

// 新增、编辑板块
function handleAdd (record) {
  RefAdd.value.showDrawer({
    // 当前平台
    platform: props.platform,
    // 单条记录id
    id: record ? record.id : undefined
  })
}

// 删除板块 
function handleDelete (record) {
  isLoading.value = true
  const params = {
    source,
    id: record.id
  }
  frontSourceDelete(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      message.success('删除成功')
      // 更新列表
      getFrontSourceList()
    } else {
      message.error(res.message || msg)
    }
  })
}

// 排序板块
function handleSort (record, type) {
  isLoading.value = true
  const params = {
    source,
    id: record.id,
    type
  }
  frontSourceSort(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 更新列表
      getFrontSourceList()
    } else {
      message.error(res.message || msg)
    }
  })
}

</script>

<style lang="less" scoped>
.tool-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 20px;
}
.table-view {
  :deep(.ant-table-cell) {
    vertical-align: top;
  }
  .play-name {
    &:not(:last-child) {
      margin-bottom: 28px;
    }
    .gap {
      margin: 0 8px;
    }
  }
}
a[disabled] {
  pointer-events: none;
  cursor: default;
}
</style>